import { useTranslations } from 'next-intl';

import { VALIDATORS } from './constants';

const VALIDATORS_STATE = ({ emailPattern, heightField, nameValidator }) => {
  const t = useTranslations();

  return {
    DUMMY_MANDATORY_FIELD: {
      required: t('mandatory_filed_error'),
    },
    NAME_VALIDATOR: {
      required: t('mandatory_filed_error'),
      pattern: {
        value: nameValidator?.pattern || VALIDATORS.NAME_PATTERN,
        message: t('name_helper_text'),
      },
      validate: {
        minChar: v => nameValidator?.min < v.length,
        maxChar: v => nameValidator?.max >= v.length,
      },
    },
    HEIGHT_VALIDATOR: {
      ...(!heightField?.notRequired
        ? {
            required: t('mandatory_filed_error'),
          }
        : {}),
      validate: {
        minHeight: v => v.length && heightField?.min <= +v,
        maxHeight: v => v.length && heightField?.max >= +v,
      },
    },
    EMAIL_VALIDATOR: {
      required: t('mandatory_filed_error'),
      pattern: {
        value: emailPattern,
        message: t('email_helper_text'),
      },
    },
    PASSWORD_VALIDATOR: {
      required: t('mandatory_filed_error'),
      minLength: {
        value: 8,
        message: t('min_8_characters'),
      },
      maxLength: {
        value: 100,
        message: t('max_100_characters'),
      },
      validate: {
        containsSymbol: value => VALIDATORS.SYMBOL_PATTERN.test(value),
        containsDigit: value => VALIDATORS.DIGIT_PATTERN.test(value),
        containsUppercaseLowercase: value =>
          VALIDATORS.UPPERCASE_LOWERCASE_PATTERN.test(value),
      },
    },
    OLD_PASSWORD_VALIDATOR: {
      required: t('mandatory_filed_error'),
      minLength: {
        value: 8,
        message: t('min_8_characters'),
      },
      maxLength: {
        value: 100,
        message: t('max_100_characters'),
      },
    },
  };
};

export default VALIDATORS_STATE;
