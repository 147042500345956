import { useEffect, useState, useCallback } from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import useMemoSelector from 'hooks/useMemoSelector';
import useMount from 'hooks/use-mount';

import Canonical from 'Components/Canonical';
import MySJSimpleTitleBar from 'Components/Dumb/MySJSimpleTitleBar';
import { LabelPicker } from 'Components/Plugins';
import Pagination from 'Components/Smart/Pagination';
import Page404 from 'Components/ErrorPages/404';
import Breadcrumbs from 'Components/Dumb/Breadcrumbs';
import Loader from 'Components/Loader';
import Filters from 'Components/Smart/Filters';
import ContentBlock from 'Components/ContentBlock';

import generateAdvertisementsPayload from 'utils/generateAdvertisementsPayload';
import { useScroll } from 'utils/ScrollProvider';
import { getRouterBasePath, getMetadataWithLocation } from 'utils/meta';
import jsonSafeParse from 'utils/jsonSafeParse';
import { convertCapitalize, Cookie } from 'utils/handlers';
import sortByTranslation from 'utils/sortByTranslation';
import { replacePlaceholders } from 'utils/config';
import { ABOVE_CONTENT, BELOW_CONTENT } from 'utils/constants';
import { getFromStorage } from 'utils/persist';

import {
  deviceParams,
  getUserGeolocation,
  getSelectedCategory,
  getSelectedSubCategory,
  getUrlStructure,
  adCategories,
  getLanguage,
  getAdConfigs,
} from 'store/reselect';
import { setSelectedSettlement, setUrlStructure } from 'store/actions';

import API from 'services/api';

import NoContent from './NoContent';
import ListingItem from './ListingItem';
import { generateBreadcrumbsPath } from './config';
import PreferencesDialog from './PreferencesDialog';

const SUGAR_DATING_CATEGORY_ID = 14;

let prevListingData;

const Listing = props => {
  const t = useTranslations();
  const router = useRouter();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [labels, setLabels] = useState(props.labels || []);
  const [listingData, setListingData] = useState(
    prevListingData || props.categoryInfo || [],
  );
  const [listingCount, setListingCount] = useState(props.categoryCount || '');
  const [selectedFilters, setSelectedFilters] = useState(
    props.selectedFilters || {},
  );

  useEffect(() => {
    if (listingData) {
      prevListingData = listingData;
    }
  }, [listingData]);

  const {
    lang,
    adConfigs,
    deviceType,
    categories,
    urlStructure,
    userGeolocation,
    selectedCategory,
    selectedSubCategory,
  } = useMemoSelector(store => ({
    lang: getLanguage(store),
    adConfigs: getAdConfigs()(store),
    categories: adCategories(store),
    urlStructure: getUrlStructure(store),
    userGeolocation: getUserGeolocation(store),
    deviceType: deviceParams()(store).deviceType,
    selectedCategory: getSelectedCategory(store, router.query.slug),
    selectedSubCategory: getSelectedSubCategory(store, router.query.slug),
  }));

  const { seek_labels_ids: seekLabels } = adConfigs;

  const sugarPreferencesFromCookie = Cookie.getCookieByKey('sugar_preferences');

  const sugarPreferencesFromSession = getFromStorage(
    'sugar_preferences',
    'sessionStorage',
  );

  const preferences = sugarPreferencesFromCookie
    ? JSON.parse(sugarPreferencesFromCookie)
    : sugarPreferencesFromSession || {};

  const hasSavedPreferences =
    !!preferences.labels?.length && !!preferences.seekLabels?.length;

  useEffect(() => {
    (async () => {
      if (
        router.query.slug.length !== 1 ||
        !router.query.slug.includes('sugar-dating') ||
        !hasSavedPreferences
      )
        return;

      try {
        const {
          data: { hash },
        } = await API.setAdSelectedFilters({
          filters: {
            seek_labels: preferences.seekLabels,
            sugar_dating_labels: preferences.labels,
          },
        });

        if (hash) {
          router.push({
            pathname: window.location.pathname,
            search: `selectedFilters=${hash}`,
          });
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.slug.length, hasSavedPreferences]);

  const {
    isValidUrl,
    isAvailableNow,
    label_ids = [],
    isValidPath,
    title: titleFromCms,
    description: descriptionFromCms,
    content,
    category_id: selectedCategoryId,
    adSettings: { position = '' } = {},
  } = urlStructure;

  const isSugarDating = selectedCategoryId === SUGAR_DATING_CATEGORY_ID;

  const {
    ref: parentRef,
    updateData,
    setSelectItem,
  } = useScroll({ deps: [listingData?.length] });

  const locationData = userGeolocation || Cookie.getCookieByKey('location');
  const userLocation =
    userGeolocation || (locationData && jsonSafeParse(locationData));

  const { title: labelTitle } = selectedSubCategory || {};

  const needToShowLabels = !!labels?.length && !isAvailableNow;

  const page = router.query.page ? router.query.page - 1 : 0;

  const excludedCategoryIdsForLabel = [1, 2];

  useEffect(() => {
    if (
      !hasSavedPreferences &&
      !!labels.length &&
      !!seekLabels.length &&
      router.query.slug.length === 1 &&
      router.query.slug.includes('sugar-dating') &&
      !router.query.selectedFilters
    ) {
      setIsOpen(true);
    }
  }, [
    labels.length,
    seekLabels.length,
    hasSavedPreferences,
    router.query.slug,
    router.query.selectedFilters,
  ]);

  const generateListingPayload = useCallback(() => {
    if (!Object.keys(urlStructure).length) return;

    const payload = generateAdvertisementsPayload({
      ...urlStructure,
      userLocation,
      term: router.query.term,
      distance: router.query.distance,
    });

    return payload;
  }, [urlStructure, userLocation, router.query.term, router.query.distance]);

  const getData = useCallback(
    async offset => {
      try {
        if (!Object.keys(urlStructure).length) return;

        setIsLoading(true);

        const payload = generateListingPayload();

        const filters = {
          ...selectedFilters,
          ...payload,
        };

        if (isAvailableNow) {
          const { data: { result = [], count } = {} } =
            await API.fetchDynamicAggregatorAds(
              'available-now',
              offset * 30,
              30,
            );

          setListingData(result);
          setListingCount(count);
        } else if (Object.keys(filters).length) {
          if (!isValidUrl) {
            throw new Error();
          }

          const {
            data: { result, count },
          } = await API.fetchAdListing({
            offset: offset * 30,
            limit: 30,
            filters,
          });
          setListingData(result);
          setListingCount(count);
        }

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      urlStructure,
      isValidUrl,
      selectedFilters,
      router.query.term,
      router.query.distance,
    ],
  );

  useMount(() => {
    return () => {
      dispatch(setUrlStructure({}));
      dispatch(setSelectedSettlement(''));
    };
  });

  useMount(() => () => setLabels([]));

  useEffect(() => {
    (async () => {
      if (router.query.selectedFilters) {
        const {
          data: { result },
        } = await API.gatAdSelectedFilters(router.query.selectedFilters);

        setSelectedFilters(result);
      }
    })();
  }, [router.query.selectedFilters]);

  useEffect(() => {
    if (
      !selectedCategoryId ||
      excludedCategoryIdsForLabel.includes(selectedCategoryId)
    ) {
      return;
    }

    const { labels } = categories.find(({ id }) => selectedCategoryId === id);

    const mutatedLabels = labels.map(({ id, title, value_nl, parentSlug }) => ({
      id,
      value_nl,
      parentSlug,
      label: title,
      redirectionPath: `${parentSlug}/${value_nl}`,
    }));

    const labelsWithCorrectSorting = isSugarDating
      ? mutatedLabels
      : sortByTranslation({ data: mutatedLabels, key: 'label' }, t);

    setLabels(labelsWithCorrectSorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, selectedCategoryId]);

  useEffect(() => {
    getData(page);
  }, [getData, page]);

  const replaceableValues = {
    AD_COUNT: listingCount,
    CITY: urlStructure.selectedCity,
    PROVINCE: convertCapitalize(urlStructure.selectedProvince),
    LABEL: labelTitle ? t(labelTitle) : '',
    CATEGORY: selectedCategory ? t(selectedCategory.title) : '',
    TERM: urlStructure.term,
    NEARBY: t('nearby_label'),
    RESULT: t('result'),
    FOR: t('for'),
    IN: 'in',
  };
  const pageHeading = urlStructure.heading
    ? replacePlaceholders(urlStructure.heading[lang], replaceableValues)
    : '';

  const { metaTitle, metaDescription } =
    getMetadataWithLocation(urlStructure?.metaData) || {};

  const { title, description } = getRouterBasePath(router.asPath);

  const searchResult = pageHeading && (
    <MySJSimpleTitleBar>
      <div className="simple-bar_title">
        <h1 className="title-content">{pageHeading}</h1>
      </div>
    </MySJSimpleTitleBar>
  );

  if (Object.keys(urlStructure).length && !isValidUrl) {
    return <Page404 />;
  }

  const noAdView = isLoading ? <Loader /> : <NoContent />;

  const hasContent = !!content?.[lang].length;

  const mutatedLabels = labels.map(({ label, id }) => ({
    id,
    label,
    value: false,
  }));

  const mutatedSeekLabels = seekLabels.map(({ name, ...rest }) => ({
    label: name,
    value: false,
    ...rest,
  }));

  return (
    <div ref={parentRef}>
      <Canonical />
      <NextSeo
        title={titleFromCms?.[lang] || metaTitle || title}
        description={
          descriptionFromCms?.[lang] || metaDescription || description
        }
        noindex={!!router.query.settlement}
      />
      <PreferencesDialog
        open={isOpen}
        labels={mutatedLabels}
        seekLabels={mutatedSeekLabels}
        onClose={() => setIsOpen(false)}
      />
      <Container>
        <Breadcrumbs
          roadMap={{
            defaultPath: 'home',
            morePaths: {
              ...generateBreadcrumbsPath(
                router.query.slug,
                selectedCategory,
                selectedSubCategory,
              ),
            },
          }}
        />
        <Row>
          <Col>
            {deviceType !== 'mobile' && searchResult}
            {isAvailableNow && (
              <MySJSimpleTitleBar>
                <div className="simple-bar_title" data-intro="secondary-bar">
                  <h4 className="title-content">
                    {t('available_now_listing_title')}
                  </h4>
                </div>
              </MySJSimpleTitleBar>
            )}

            {needToShowLabels && (
              <LabelPicker
                labels={labels}
                isValidPath={isValidPath}
                currentLabelId={label_ids[0]}
              />
            )}
            <Filters generateListingPayload={generateListingPayload} />
            {deviceType === 'mobile' && searchResult}
            {!isLoading && (
              <div>{position === BELOW_CONTENT && <ContentBlock />}</div>
            )}
            {listingData?.length
              ? listingData.map((ad, index) => (
                  <ListingItem
                    ad={ad}
                    index={index}
                    key={ad.reference_id}
                    updateData={updateData}
                    setSelectItem={setSelectItem}
                  />
                ))
              : !hasContent
              ? noAdView
              : null}
            {listingCount > 0 && (
              <Pagination
                totalPages={Math.ceil(listingCount / 30)}
                emitPageChange={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              />
            )}
            {!isLoading && (
              <div>{position === ABOVE_CONTENT && <ContentBlock />}</div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Listing;
